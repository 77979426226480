.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: rgba(33, 33, 33, 0.8);
}

.loader-gif {
  width: 100px;
  height: auto;
}

.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(55, 54, 54, 0.8);
  /* background-color: rgba(0, 0, 0, 0.3); */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}
