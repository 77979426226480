/* body
{
  margin:0;
  padding:0;
  background:#262626;
} */

html,
body {
    /* max-width: 100%; */
    overflow-y: hidden;
}

.ring-wrapper {
    display: block;
    position: absolute;
    /* background: #222639; */
    /* background-color: #262626; */
    width: 100%;
    height: 100%;
    /* overflow: hidden; */
    overflow-y: hidden;
    opacity: 100%;
    /*pointer-events: none;*/
    /* z-index: 999; */
}

.ring {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 150px;
    height: 150px;
    background: transparent;
    border: 3px solid #3c3c3c;
    border-radius: 50%;
    text-align: center;
    line-height: 150px;
    font-family: sans-serif;
    font-size: 20px;
    color: #fff000;
    letter-spacing: 4px;
    text-transform: uppercase;
    text-shadow: 0 0 10px #fff000;
    box-shadow: 0 0 20px rgba(0, 0, 0, .5);
}

@media only screen and (max-height: 575.98px) and (orientation: landscape) {
    .ring {
        width: 100px;
        height: 100px;
        transform: translate(-50%, -80%);
        line-height: 100px;
        font-size: 12px;
        letter-spacing: 2px;
        text-shadow: 0 0 10px #fff000;
    }
}

.ring:before {
    content: '';
    display: block;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    border: 3px solid transparent;
    border-top: 3px solid #fff000;
    border-right: 3px solid #fff000;
    border-radius: 50%;
    animation: animateC 2s linear infinite;
}

.ring-span {
    display: block;
    position: absolute;
    top: calc(50%);
    left: 50%;
    width: 50%;
    height: 4px;
    background: transparent;
    transform-origin: left;
    animation: animate 2s linear infinite;
}

.ring-span:before {
    content: '';
    position: absolute;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: #fff000;
    top: -35px;
    left: 56px;
    box-shadow: 0 0 20px #fff000;
}

@keyframes animateC {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes animate {
    0% {
        transform: rotate(45deg);
    }

    100% {
        transform: rotate(405deg);
    }
}

@keyframes fade-out {
    from {
        opacity: 100%;
    }

    to {
        opacity: 0%;
    }
}

@keyframes fade-in {
    from {
        opacity: 0%;
    }

    to {
        opacity: 100%;
    }
}