* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.revamped-wrapper {
  height: 100svh;
  overflow-y: scroll;
  background: #040a16 !important;
  font-family: $primary-font-family !important;
  color: #fff !important;

  ::selection {
    background: #c86c05 !important;
  }

  h1,
  h2,
  h3 {
    margin-bottom: 0;
    font-family: $secondary-font-family;
    font-weight: normal;
    user-select: none;
  }

  p {
    margin-bottom: 0;
  }

  a {
    text-decoration: none;
    color: inherit;

    &:hover {
      color: #fff;
    }
  }

  li {
    list-style-type: none;
  }

  button,
  input,
  textarea,
  select {
    outline: none;
    border: none;
    background: none;
    font-family: inherit;
    color: inherit;
  }

  button {
    cursor: pointer;
  }

  img {
    user-select: none;
  }

  .container {
    margin: 0 auto;
    width: 95%;
    max-width: 1340px;
  }
}
