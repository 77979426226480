.App {
  text-align: center;
}

html,
body {
  max-width: 100%;
  overflow-x: hidden;
}

/* .MuiFab-sizeSmall {
  height: 20;
} */

@keyframes spin {
  0% {
    transform: translate(0, 0) scale(1) rotateY(0deg);
  }

  25% {
    transform: translate(-5rem, -5rem) scale(1) rotateY(90deg);
  }

  50% {
    transform: translate(-5rem, 5rem) scale(1) rotateY(180deg);
  }

  75% {
    transform: translate(5rem, 5rem) scale(1) rotateY(270deg);
  }

  100% {
    transform: translate(0rem, 0rem) scale(1) rotateY(360deg);
  }
}

.spin-animation {
  animation: spin 2s linear forwards;
}

.msg {
  display: flex;
  align-items: flex-end;
  margin-bottom: 10px;
}

.msg-bubble {
  max-width: 300px;
  padding: 10px;
  border-radius: 8px;
  background: rgb(84, 86, 86);
  height: auto;
}

.left-msg .msg-bubble {
  border-bottom-left-radius: 0;
}

.right-msg {
  flex-direction: row-reverse;
}

.hexagon-icon {
  -webkit-clip-path: polygon(50% 0%,
      100% 25%,
      100% 75%,
      50% 100%,
      0% 75%,
      0% 25%);
  clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
}

th,
td {
  /* padding: 1;
  text-align: left; */
  border: solid 2px rgb(188, 185, 185);
}

td {
  font-size: 20px;
  font-family: Montserrat, sans-serif;
  font-weight: 1000;
  text-transform: none;
  margin: 0px auto 0;
  width: fit-content;
  color: rgb(206, 203, 203);
}


.text_shadows_fight_announcement {
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(80%, 50%);
  text-transform: uppercase;
  font-family: 'Cooper Black', sans-serif;
  font-size: 3vw;
  font-weight: 700;
  color: #720707;
  margin-left: auto;
  margin-right: auto;
  text-shadow: 1px 1px 1px #1c1b1b,
    1px 2px 1px #1c1b1b,
    1px 3px 1px #1c1b1b,
    1px 4px 1px #1c1b1b,
    1px 5px 1px #1c1b1b,
    1px 6px 1px #1c1b1b,
    1px 7px 1px #1c1b1b,
    1px 8px 1px #1c1b1b,
    1px 9px 1px #1c1b1b,
    1px 10px 1px #1c1b1b,
    1px 18px 6px rgba(16, 16, 16, 0.4),
    1px 22px 10px rgba(16, 16, 16, 0.2),
    1px 25px 35px rgba(16, 16, 16, 0.2),
    1px 30px 60px rgba(16, 16, 16, 0.4);
}

.text_shadows_success_text_modal {
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(80%, 50%);
  text-transform: uppercase;
  font-family: 'Cooper Black', sans-serif;
  font-size: 6em;
  font-weight: 700;
  color: #973232;
  text-shadow: 1px 1px 1px #1c1b1b,
    1px 2px 1px #1c1b1b,
    1px 3px 1px #1c1b1b,
    1px 4px 1px #1c1b1b,
    1px 5px 1px #1c1b1b,
    1px 6px 1px #1c1b1b,
    1px 7px 1px #1c1b1b,
    1px 8px 1px #1c1b1b,
    1px 9px 1px #1c1b1b,
    1px 10px 1px #1c1b1b,
    1px 18px 6px rgba(16, 16, 16, 0.4),
    1px 22px 10px rgba(16, 16, 16, 0.2),
    1px 25px 35px rgba(16, 16, 16, 0.2),
    1px 30px 60px rgba(16, 16, 16, 0.4);
}

.name_text_below_health_bar {
  text-transform: uppercase;
  font-family: 'Cooper Black', sans-serif;
  font-size: 4em;
  font-weight: 600;
  color: #ab3613;
  text-shadow: 2px 0 #fff, -2px 0 #fff, 0 2px #fff, 0 -2px #fff,
    1px 1px #fff, -1px -1px #fff, 1px -1px #fff, -1px 1px #fff;
  /* text-shadow: 1px 1px 1px #1c1b1b,
        1px 2px 1px #1c1b1b,
        1px 3px 1px #1c1b1b,
        1px 4px 1px #1c1b1b,
        1px 5px 1px #1c1b1b,
        1px 6px 1px #1c1b1b,
        1px 7px 1px #1c1b1b,
        1px 8px 1px #1c1b1b,
        1px 9px 1px #1c1b1b,
        1px 10px 1px #1c1b1b, */
}

.prize_text_below_health_bar {
  /* text-transform: uppercase; */
  white-space: nowrap;
  font-family: 'Cooper Black', sans-serif;
  font-size: 1.5em;
  font-weight: 600;
  color: #60d481;
  text-shadow: 2px 0 rgb(21, 14, 14), -2px 0 rgb(21, 15, 15), 0 2px rgb(4, 3, 3), 0 -2px rgb(1, 0, 0),
    1px 1px rgb(8, 6, 6), -1px -1px rgb(36, 30, 30), 1px -1px rgb(21, 19, 19), -1px 1px rgb(17, 13, 13);
}

.bg-SOME_NAME {
  background-color: #e52929;
}

.prevent-select {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.cooper-black-tab {
  font-family: 'Cooper Black', sans-serif;
  font-size: 16px;
  white-space: nowrap;
}


.mint_box_shadow {
  box-shadow: 1px 1px 1px #808386,
    1px 2px 1px #808386,
    1px 3px 1px #808386,
    1px 4px 1px #808386,
    1px 5px 1px #808386,
    1px 6px 1px #808386,
    1px 7px 1px #808386,
    1px 8px 1px #808386,
    1px 9px 1px #808386,
    1px 10px 1px #808386,
    /* 1px 18px 6px rgba(16,16,16,0.4), */
    /* 1px 22px 10px rgba(16,16,16,0.2), */
    /* 1px 25px 35px rgba(16,16,16,0.2), */
    10px 10px 10px rgba(38, 37, 37, 0.4);
}


.bg-ORANGE {
  background-color: #ed764f;
}


/* Drop Down Menu */
.dropdown-menu {
  display: none;
  color: rgb(206, 203, 203);
}

.dropdown:hover .dropdown-menu {
  display: block;
}

.dropdown-menu li {
  background-color: #333;
  color: white;
  padding: 8px 12px;
}

.dropdown-menu li a {
  text-decoration: none;
  color: white;
}

.dropdown-menu li a:hover {
  background-color: #555;
}


.navbar-custom {
  height: 50px;
}

/* Example of responsive height change */
/* @media (max-width: 768px) {
    .navbar-custom {
        height: 60px;
    }
} */

@media only screen and (max-height: 575.98px) and (orientation: landscape) {
  .navbar-custom {
    height: 40px;
  }

  .navbar-custom button {
    height: 30px
  }
}