.social-links-component {
  display: flex;
  align-items: center;
  gap: 18px;

  a {
    display: flex;

    svg path {
      transition: all 0.2s ease;
    }

    &:hover svg path {
      fill: #c86c05;
    }
  }
}
