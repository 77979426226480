.footer-component {
  overflow: hidden;

  .footer-top {
    position: relative;
    background: url('./images/footer-component-bg.webp');
    background-size: cover;
    background-position: center bottom;
    overflow: hidden;

    .top-fader {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(180deg, #040a16 0%, rgba(4, 10, 22, 0) 100%);
    }

    .container {
      position: relative;
      z-index: 2;
      display: flex;
      flex-direction: column;
      align-items: center;

      .h2-wrapper {
        position: relative;
        z-index: 2;
        margin-bottom: 100px;
        width: fit-content;
        opacity: 0.2;

        h2 {
          font-size: 180px;
          line-height: 100px;
          text-align: center;
          text-transform: uppercase;

          @media only screen and (max-width: 1024px) {
            font-size: 120px;
            line-height: 70px;
          }

          &.text {
            position: relative;
            z-index: 3;
            background: radial-gradient(50% 50% at 50% 50%, #fff 0%, #989898 250%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }

          &.text-stroke {
            position: absolute;
            top: 0px;
            left: 0px;
            z-index: 2;
            white-space: nowrap;
            @include text-stroke(2px, #040a16);
          }

          &.text-shadow {
            position: absolute;
            top: 2px;
            left: 2px;
            white-space: nowrap;
            color: #000;
            @include text-stroke(2px, #040a16);
          }
        }
      }

      .logo-img-wrapper {
        position: relative;
        z-index: 2;
        margin-top: -150px;
        margin-bottom: 200px;
        display: flex;

        @media only screen and (max-width: 1024px) {
          margin-top: -130px;
        }

        @media only screen and (max-width: 768px) {
          margin-bottom: 120px;
        }

        img {
          width: 150px;

          @media only screen and (max-width: 1024px) {
            width: 120px;
          }
        }
      }

      .img-1 {
        position: absolute;
        bottom: 0;
        left: -100px;
        width: 650px;
        pointer-events: none;

        @media only screen and (max-width: 1280px) {
          display: none;
        }
      }

      .img-2 {
        position: absolute;
        bottom: 0;
        right: -100px;
        width: 650px;
        pointer-events: none;

        @media only screen and (max-width: 1280px) {
          display: none;
        }
      }
    }
  }

  .imgs-wrapper {
    position: relative;
    z-index: 2;
    margin-bottom: -15px;
    display: flex;
    transform: translateX(0);
    height: 100%;
    display: flex;
    align-items: center;
    gap: 80px;
    animation: mineSectionImgsMarquee 30s linear infinite;

    @media only screen and (max-width: 768px) {
      gap: 50px;
      animation: mineSectionImgsMarquee 15s linear infinite;
    }

    @keyframes mineSectionImgsMarquee {
      0% {
        transform: translateX(0);
      }
      100% {
        transform: translateX(-100%);
      }
    }

    img {
      width: 164px;

      @media only screen and (max-width: 768px) {
        width: 124px;
      }
    }
  }

  .track {
    margin-bottom: -3px;
    width: 100%;
    min-width: 1340px;
    height: 30px;
    background: url('./images/landing-page-mine-section-track.webp');
    background-size: contain;
    background-repeat: repeat-x;
  }

  .footer-bottom {
    padding: 35px 0;

    .container {
      display: flex;
      justify-content: space-between;
      align-items: center;

      @media only screen and (max-width: 1024px) {
        flex-direction: column;
        gap: 25px;
      }

      p {
        font-size: 10px;
        color: rgba(255, 255, 255, 0.75);

        @media only screen and (max-width: 1024px) {
          order: 2;
          text-align: center;
        }

        @media only screen and (max-width: 768px) {
          font-size: 9px;
          line-height: 18px;
        }
      }
    }
  }
}
