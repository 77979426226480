@import "../../../assets/sass/mixins";
@import "../../../assets/sass/variables";

.mint-card-base__wrapper{
  position: relative;
}
.mint-card-base {
  position: relative;
  height: 580px;
  width: 366px;
  background-image: url("../../../assets/images/card-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  font-family: "Cooper Black";
  line-height: 1;
  &__wrapper {
    display: flex;
    .side-panel {
      margin-top: 30px;
    }
  }
  &__layer {
    height: 100%;
    width: 100%;
    background-image: url("../../../assets/images/card-bg-layer.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
  &__inner {
    padding: 18px 5px 5px 30px;
    position: relative;
    height: 100%;
  }
  &__status {
    display: flex;
    margin: 0 0 3px 105px;
    align-items: center;
    gap: 5px;
    &__title {
      height: 30px;
      width: 110px;
      background-color: $dark;
      color: $white;
      border-radius: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-left: 5px;
      p {
        font-size: 24px;
         letter-spacing: 1px;
         margin: 0;
      }
    }
  }

  &__display {
    height: 140px;
    width: 320px;
    @include mintcardborder;
    margin-bottom: 2px;
    &__inner {
      background-color: $dark;
      border: 1px solid #2d2c2c;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;
      p {
        font-size: 24px;
        line-height: 1.3;
        letter-spacing: 2px;
        margin: 0;
        color: $white;
      }
      h1{
        color: #fff;
        font-size: 40px;
      }
      h4 {
        font-size: 32px;
        margin: 0;
        color: $white;
      }
      h5 {
        font-size: 21px;
        letter-spacing: 1px;
        margin: 0;
        color: $white;
      }
    }
  }

  &__info {
    width: 320px;
    height: 230px;
    position: relative;
    @include mintcardborder;
    &__inner {
      width: 100%;
      height: 100%;
      padding: 3px 3px 8px;
      min-height: 105px;
      background-color: $light-gray;
      display: flex;
      align-items: center;
      flex-direction: column;
      .makeselection-img {
        margin-top: 20%;
      }
      .progress-info--bar {
        position: absolute;
        bottom: 65px;
      }
      h5{
        margin: 0;
      }
    }
    &__btc {
      position: absolute;
      bottom: 15px;
      width: auto;
      padding: 10px;
      height: 40px;
      background-color: $white;
      border-radius: 8px;
      @include flexcenter;
      gap: 10px;
      span {
        font-size: 22px;
        color: $dark;
      }
      p{
        font-size: 12px;
        color: $dark;
      }
    }
    &__btc2 {
      position: absolute;
      bottom: 15px;
      // width: auto;
      // padding: 10px;
      height: 40px;
      background-color: $white;
      border-radius: 8px;
      @include flexcenter;
      gap: 10px;
      span {
        font-size: 22px;
        color: $dark;
      }
      p{
        font-size: 12px;
        color: $dark;
      }
    }
    &__checkBox {
      position: absolute;
      bottom: 15px;
      width: auto;
      padding: 10px;
      height: 40px;
      background-color: $white;
      // border-radius: 8px;
      // @include flexcenter;
      // gap: 10px;
      // span {
      //   font-size: 22px;
      //   color: $dark;
      // }
    }
    &__sold {
      font-size: 40px;
      color: $white;
      text-shadow: -2px 0 #000, 0 2px #000, 2px 0 #000, 0 -2px #000;
      margin: 0;
    }
  }

  &__footer {
    position: absolute;
    left: 18px;
    bottom: 0;
    width: calc(100% - 18px);
    padding-right: 3px;
    padding-bottom: 3px;
    @include flexcenter;
    flex-direction: column;
    div {
      width: 200px;
      height: 50px;
    }
    .footer-connect {
      background-image: url("../../../assets/images/work/connect_origin.png");
      background-size: 100% 100%;
    }
    .footer-connect:hover {
      background-image: url("../../../assets/images/work/connect_hover.png");
    }
    .footer-connect:active {
      background-image: url("../../../assets/images/work/connect_active.png");
    }
    .footer-go {
      background-image: url("../../../assets/images/work/go_origin.png");
      background-size: 100% 100%;
    }
    .footer-go:hover {
      background-image: url("../../../assets/images/work/go_hover.png");
    }
    .footer-go:active {
      background-image: url("../../../assets/images/work/go_active.png");
    }
    img {
      margin-top: 14px;
      margin-bottom: 30px;
    }
    h3 {
      width: 100%;
      text-align: right;
      font-size: 22px;
      color: #8a939c;
      margin: 0;
      line-height: 1;
    }
  }
}

#GoBtn {
  width: 200px;
  height: 100px;
  background-color: aqua;
}

.mint-card-honey{
  background-image: url(../../../assets/images/Mint_Panel__0004_Slime.png);
}
.mint-card-base{
  position: relative;
}
.mint-card-base::after{
  content: "";
  background-image: url(../../../assets/images/Mint_Panel__0004_Slime.png);
  width: 382px;
  height: 590px;
  display: block;
  position: absolute;
  top: -6px;
  left: -16px;
  z-index: 10;
}

.side-panel__inner{
  position: relative;
}
.side-panel__inner::after{
  background-image: url(../../../assets/images/Mint_Panel__0004_Slime.png);
  content: "";
  position: absolute;
  width: 174px;
  height: 320px;
  background-position-x: -384px;
  background-position-y: -23px;
  /* background-size: 335%; */
  left: 0px;
  top: -8px;
}

.btn-mint--small{
  position: relative;
  z-index: 100;
  width: 154px;
  margin-left: 2px;
}

.btn-mint--red{
  position: relative;
  z-index: 100;
}

.custom-standard{
  position: relative;
  z-index: 1;
}
.bottom-button-cover{
  width: 160px;
  height: 50px;
  background-color: #676666;
  position: absolute;
  bottom: 4px;
  left: 4px;
  height: 67px !important;
}
.kLurgu{
  background-color: #363636 !important;
}

.main{
  overflow-x: unset !important;
}

.jSiiiW{
  background-color: #db5b12 !important;
}
.daSwHP{
  background-color: #db5b12 !important;
}

.sidePanel-refrink-disabled{
  margin-top: 35px;
}

.custom-disabled{
  background-image: url(../../../assets/images/work/Mint_Panel__0003_spareBTN.png) !important;
}