@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300&family=Rubik+Bubbles&display=swap');

.secondary {
  background-color: #f5f7fb;
}
.justify-center {
  justify-content: center;
}
.justify-start {
  justify-content: start !important;
}
.mb-130 {
  margin-bottom: 130px;
}
.fs-14 {
  font-size: 14px;
}
.fs-15 {
  font-size: 15px !important;
}
.fs-12 {
  font-size: 12px;
}

.fs-10 {
  font-size: 10px;
}

.fs-20 {
  font-size: 20px;
}
.fs-30 {
  font-size: 30px;
}
.fs-25 {
  font-size: 25px;
}
.fw-500 {
  font-weight: 500;
}
.fw-400 {
  font-weight: 400;
}

.fw-300 {
  font-weight: 300;
}

.fw-250 {
  font-weight: 250;
}

.fw-200 {
  font-weight: 200;
}
.fw-700 {
  font-weight: 700;
}
.pt-20 {
  padding-top: 20px;
}
.p-0-20 {
  padding: 0px 20px;
}

.pt-56 {
  padding-top: 56px;
}
.gap-8 {
  gap: 8px;
}
.gap-4 {
  gap: 4px;
}
.gap-35 {
  gap: 35px;
}

.text-grey {
  color: #1c1c1c99;
}

.text-black {
  color: #1c1c1c;
}
.text-light-black {
  color: #1c1c1cb2;
}
.text-light-gradiant {
  color: #ff8a00;
}

.pt-40 {
  padding-top: 40px !important;
}

.main_title {
  color: #1c1c1c;
  font-family:
    Poppins,
    Consolas,
    Courier New,
    monospace;
  /* font-size: 20px; */
  font-weight: 600;
}
.sub_title {
  font-family:
    Poppins,
    Consolas,
    Courier New,
    monospace;
  /* font-size: 16px; */
  font-weight: 400;
  line-height: 26px;
}
.fs-16 {
  font-size: 16px !important;
}
.fs-80 {
  font-size: 80px !important;
}

.inter {
  font-family:
    Poppins,
    Consolas,
    Courier New,
    monospace !important;
}

.poppins {
  font-family:
    Poppins,
    Consolas,
    Courier New,
    monospace !important;
}

.gap-10 {
  display: flex;
  gap: 10px;
}
.gap-16 {
  display: flex;
  gap: 16px;
}
.mt-20 {
  margin-top: 20px !important;
}
.mt-30 {
  margin-top: 30px !important;
}
.mt-35 {
  margin-top: 35px;
}
.mt-60 {
  margin-top: 60px;
}
.px-30 {
  padding-left: 30px;
  padding-right: 30px;
}
.ht-100vh {
  height: 100% !important;
}
.ht-50vh {
  height: 50%;
}
.p-10 {
  padding: 10px !important;
}
.p-14 {
  padding: 14px !important;
}
.p-40 {
  padding: 40px !important;
}
.mb-20 {
  margin-bottom: 20px !important;
}
.mb-50 {
  margin-bottom: 50px !important;
}
.ml-50 {
  margin-left: 50px !important;
}
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.flex-start {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.img-responsive {
  display: block;
  max-width: 100%;
  height: auto;
}
.text-white {
  color: white;
}
.text-black {
  color: black !important;
}
.mt-5 {
  margin-top: 5px !important;
}
.h-20 {
  height: 20% !important;
}

.mt-10 {
  margin-top: 10px !important;
}
.pb-20 {
  padding-bottom: 20px !important;
}
.text-center {
  text-align: center;
}
.line-ht-25 {
  line-height: 25px;
}
.flex-end {
  display: flex;
  justify-content: end;
}
.display-column {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.justify-end {
  justify-content: end !important;
}

.column {
  flex-direction: column !important;
}
.row {
  flex-direction: row !important;
}

.d-flex {
  display: flex;
}

.d-flex-reverse {
  display: flex;
  flex-direction: row-reverse;
}

.space-between {
  justify-content: space-between;
}

.align-center {
  align-items: center !important;
}
.w-94 {
  width: 94%;
}
.w-180 {
  width: 180px;
}
.w-125 {
  width: 125px;
}

.dflex {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.g-8 {
  gap: 8px;
}
.row-center {
  display: flex;
  align-items: center;
  gap: 8px;
}
.relative {
  position: relative !important;
}
.w-420 {
  width: 420px !important;
}

.br-right {
  border-right: 1px solid #d0d5dd;
}
.br-bottom {
  border-bottom: 1px solid #d0d5dd;
}
.bodeR_left-12 {
  border-top-left-radius: 12px !important;
  border-bottom-left-radius: 12px !important;
}
.bodeR_right-12 {
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
}
.bodeR_right-left-bottom-12 {
  border-bottom-left-radius: 12px !important;
  border-bottom-right-radius: 12px !important;
}

.ml-32 {
  margin-left: 40px !important;
}
.ml-45 {
  margin-left: 45px;
}

.text-light-grey {
  color: #1c1c1c99;
}
.mt-24 {
  margin-top: 24px;
}
.line-ht-15 {
  line-height: 15px;
}
.line-ht-18 {
  line-height: 18px;
}
.line-ht-20 {
  line-height: 20px;
}
.line-ht-22 {
  line-height: 22px;
}
.line-ht-24 {
  line-height: 24px;
}
.line-ht-26 {
  line-height: 26px;
}
.line-ht-40 {
  line-height: 40px;
}

.line-ht-32 {
  line-height: 32px;
}

.w-85 {
  width: 85%;
}
.fw-600 {
  font-weight: 600 !important;
}
.pt-64 {
  padding-top: 64px;
}
.text-lighter-black {
  color: #1c1c1ccc;
}
.p-12 {
  padding: 12px !important;
}
.p-8 {
  padding: 8px !important;
}
.p-15 {
  padding: 15px !important;
}
.px-12 {
  padding-left: 12px;
  padding-right: 12px;
}
.px-16 {
  padding-left: 16px !important;
  padding-right: 16px !important;
}
.pt-16 {
  padding-top: 16px;
}
.mt-8 {
  margin-top: 8px;
}
.ht-100 {
  height: 100% !important;
}
.mt-100 {
  margin-top: 100px;
}
.mt-50 {
  margin-top: 50px;
}
.mt-40 {
  margin-top: 40px;
}
.ml-20 {
  margin-left: 20px !important;
}

.w-100 {
  width: 100% !important;
}
.w-100px {
  width: 100px;
}
.w-50px {
  width: 50px;
}
.w-150px {
  width: 150px;
}
.w-140px {
  width: 140px;
}
.w-130px {
  width: 130px;
}
.mr-24 {
  margin-right: 24px;
}
.mr-200 {
  margin-right: 200px !important;
}
.b-top {
  border-top: 1px solid #eaecf0;
}
.gap-24 {
  gap: 24px;
}
.p-24 {
  padding: 24px !important;
}
.p-48 {
  padding: 48px !important;
}
.p-16 {
  padding: 16px !important;
}
.p-18 {
  padding: 18px !important;
}
.p-20 {
  padding: 20px !important;
}
.p-30 {
  padding: 30px !important;
}

.absolute {
  position: absolute;
}

.bottom-20 {
  bottom: 0px;
}

.box-shadow {
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1) !important;
}
.mt-64 {
  margin-top: 64px;
}
.mt-6 {
  margin-top: 65px;
}

.pt-10 {
  padding-top: 10px;
}
.text-IC {
  color: #1c1c1c;
}
.ptb-24 {
  padding: 24px 0;
}
.fw-700 {
  font-weight: 700;
}

.pb-4 {
  padding-bottom: 4px;
}

.pb-8 {
  padding-bottom: 8px;
}

.pt-40 {
  padding-top: 40px;
}
.pt-8 {
  padding-top: 8px;
}
.cursor-pointer {
  cursor: pointer;
}
.content-center {
  align-content: center;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.max-ht-65 {
  max-height: 65px !important;
}
.max-ht-90 {
  max-height: 90px !important;
}
.pb-16 {
  padding-bottom: 16px !important;
}
.gap-20 {
  gap: 20px;
}
.gap-200 {
  gap: 200px;
}
.mt-40 {
  margin-top: 40px;
}
.text-blackish {
  color: #333333 !important ;
}
.avg_parent {
  border-radius: 8px;
  background: #fff;
  padding: 12px;
  color: #1d2939;
}
.text-1d {
  color: #1d2939;
}
.pr-24 {
  padding-right: 24px;
}
.ps-12 {
  padding-left: 12px;
}
.px-24 {
  padding-left: 24px !important;
  padding-right: 24px !important;
}
.py-10 {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}
.basis-25 {
  flex-basis: 25%;
}
.basis-50 {
  flex-basis: 50%;
}
.basis-75 {
  flex-basis: 75%;
}
.basis-60 {
  flex-basis: 60%;
}
.ht-160 {
  height: 160px;
}

.provider-video-height {
  height: 220px;
}

.accordion-rating-text {
  width: 199px;
  height: 36px;
  flex-shrink: 0;
  border-radius: 24px;
}
.align-baseline {
  align-items: baseline;
}
.gap-36 {
  gap: 36px;
}
.w-146 {
  width: 146px;
}
.transform-normal {
  text-transform: none !important;
}
.ml-24 {
  margin-left: 24px;
}
.ml-16 {
  margin-left: 8px !important;
}
.ml-40 {
  margin-left: 40px !important;
}
.ml-16c {
  margin-left: 16px !important;
}
.pt-12 {
  padding-top: 12px !important;
}
.textDecoration-none {
  text-decoration: none !important;
}

.evaluate-plus-icon {
  display: flex;
  padding: var(--Gap-8, 8px);
  justify-content: center;
  align-items: center;
}

.button-header {
  color: var(--Gray-700, #344054) !important;
  font-family:
    Poppins,
    Consolas,
    Courier New,
    monospace !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 24px !important;
  border-radius: 8px !important;
  border: 1px solid #d0d5dd !important;
  background: #fff !important;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05) !important;
  height: 36px;
  padding: 0 5px;
  text-decoration: none !important;
}

.bold {
  font-weight: bold;
}
.underline {
  text-decoration: underline;
}
.bg-red {
  border: 2px solid red !important;
}

.add-provider-text {
  display: flex;
  width: 541px;
  height: 40px;

  justify-content: center;
  align-items: center;
  font-family:
    Poppins,
    Consolas,
    Courier New,
    monospace !important;
}
.text-comment {
  width: 260px;
  overflow: hidden !important;
  height: auto;
  padding: 16px;
  font-family:
    Poppins,
    Consolas,
    Courier New,
    monospace !important;
}

.add-provider-text-phone {
  display: flex;
  width: 100%;
  height: 51px;
  padding: 10px 8px;
  justify-content: center;
  align-items: center;
  background: #f2f4f7;
  border: none;
  font-family:
    Poppins,
    Consolas,
    Courier New,
    monospace !important;
}

.add-provider-text-phone12 {
  display: flex;
  width: 100%;
  height: 41px;
  padding: 10px 8px;
  justify-content: center;
  align-items: center;
  background: #f2f4f7;
  border: none;
  font-family:
    Poppins,
    Consolas,
    Courier New,
    monospace !important;
  font-size: 14px !important;
}
.add-provider-text-phone-create {
  display: flex;
  width: 100%;
  height: 58px;
  padding: 10px 8px;
  justify-content: center;
  align-items: center;
  background: #f2f4f7;
  border: none;
  font-family:
    Poppins,
    Consolas,
    Courier New,
    monospace !important;
}

.add-provider-main {
  display: flex;
  padding: 20px 24px 0px 24px;
  padding-bottom: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--Gap-16, 16px);
  align-self: stretch;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}
.add-provider-main-project {
  display: flex;
  padding: 40px 24px 0px 24px;
  padding-bottom: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--Gap-16, 16px);
  align-self: stretch;
  border-radius: 16px;
}

.add-provider-middle-content {
  display: flex;
  width: 100%;
  margin-top: 10px;
  justify-content: space-between !important;

  align-items: center !important;
}

.sss {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
.sss::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.projectBriefAnswer div textarea {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
/* hide scollbar */
/* ::-webkit-scrollbar {
  display: none;
} */

.zoom-on-hover {
  transition: transform 0.3s;
}

.zoom-on-hover:hover {
  transform: scale(1.2);
}

.scrollable-content {
  overflow-x: auto;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  outline: none;
}

.scrollable-content::-webkit-scrollbar {
  display: none;
}

.scrollable-content::-webkit-scrollbar-track {
  background-color: hsl(250 15% 93%);
}

.scrollable-content::-webkit-scrollbar-thumb {
  background-color: hsl(250 15% 60%);
}

.w-95 {
  width: 95% !important;
}
.p-0 {
  padding: 0 !important;
}
.pl-0 {
  padding-left: 0 !important;
}
.pl-10 {
  padding-left: 10px !important;
}
.pb-40 {
  padding-bottom: 40px !important;
}
.text-47 {
  color: #475467;
}
.bg-accdr {
  background: #f7f9fb !important;
}
.top-1 {
  top: 2px;
  z-index: 32;
  left: -40px;
}
.ml-36 {
  margin-left: 36px;
}
.ml-30 {
  margin-left: 30px;
}

.br-top {
  border-top: 1px solid #e5ecf6 !important;
}
.acc-line-ht {
  height: 71%;
}
.acc-line-ht-0 {
  height: 0px;
  transition: all 0.1ms ease-in-out;
}
.acc-line {
  width: 4px;
  position: absolute;
  border-radius: 34px;
  background: rgba(28, 28, 28, 0.05);
  top: 62px;
  left: -30px;
}
.ml-52 {
  margin-left: 52px;
}
.text-66 {
  color: #667085 !important;
}
.fs-32 {
  font-size: 32px;
}
.fs-48 {
  font-size: 48px;
}
.fs-50 {
  font-size: 50px;
}
.line-ht-44 {
  line-height: 44px;
}
.min-ht-700 {
  height: 700px;
}

.poppins {
  font-family:
    Poppins,
    Consolas,
    Courier New,
    monospace !important;
}

.main_content {
  display: flex;
  justify-content: center;
  padding: 30px;
}
.grab {
  cursor: grab !important;
}
.m-0 div:first-child {
  margin: 0px !important;
}
.react-multi-carousel-list {
  position: unset !important;
}
.p-8 {
  padding: 8px;
}
.text-82 {
  color: #828282 !important;
}
.pt-4 {
  padding-top: 4px;
}
.mr-100 {
  margin-right: 100px;
}
.w-72 {
  width: 72%;
}
.align-start {
  align-items: flex-start !important;
}
.pb-12 {
  padding-bottom: 12px !important;
}

.selected-right-menu {
  display: flex;
  /* padding: 16px; */
  align-items: flex-start;
  gap: 8px;
  background: rgba(229, 236, 246, 0.5);
}
.mtb-0 div:first-child {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}
.ht-600 {
  height: 600px !important;
}

.acc_slider span:first-child {
  background: #eaecf0 !important;
  height: 8px !important;
}

.acc_slider span:nth-child(2) {
  background: linear-gradient(83deg, #ff8a00 0%, #ffc000 100%) !important;
  height: 8px;
}
.acc_slider_black span:first-child {
  background: #eaecf0 !important;
  height: 8px !important;
}

.acc_slider_black span:nth-child(2) {
  background: #000000 !important;
  height: 8px;
}
.css-inh5fo-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border: none !important;
  border: 1px solid #fff !important;
}
.text-344 {
  color: #344054;
}
.w-128px {
  width: 128px !important;
}
.br-8 {
  border-radius: 8px !important;
}
.br-16 {
  border-radius: 16px !important;
}

.pb-0 {
  padding-bottom: 0px !important;
}

.pt-6 {
  padding-top: 6px !important;
}

.chip {
  font-family:
    Poppins,
    Consolas,
    Courier New,
    monospace !important;
  font-size: '14px';
  background-color: #f2f4f7;
  /* margin-right: 12px; */
}

.w-585 {
  width: 585px;
}

.w-741 {
  width: 741px;
}
.gap-12 {
  gap: 12px;
}
.mt-16 {
  margin-top: 16px;
}
.mt-12 {
  margin-top: 13px;
}

.ml-0 {
  margin-left: 0px !important;
}
.mr-0 {
  margin-right: 0px !important;
}

.br-btm {
  border-bottom-left-radius: 12px !important;
  border-bottom-right-radius: 12px !important;
}
.br-12-top {
  border-top-left-radius: 12px !important;
  border-top-right-radius: 12px !important;
}

.eva-select {
  padding: 14px 3px;
  border-radius: 8px;
  font-size: 12px;
  font-family: 'Poppins', sans-serif;
  background: var(--BG-F2F4F4, #f2f4f7);
  color: #828282;
  border: none;
}

.eva-select option {
  font-size: 14px;
  font-family: 'Poppins', sans-serif;
}
.justifyCenter {
  justify-content: center;
}
.text-normal {
  text-transform: none !important;
}
.px-8 {
  padding: 0 18px !important;
}
.mb-16 {
  margin-bottom: 16px !important;
}

.mr-40 {
  margin-right: 40px;
}
.mt-15 {
  margin-top: 15px !important;
}

.disable-clr {
  color: #828282;
}
.text-normal {
  text-transform: none !important;
}
.px-8 {
  padding: 0 18px !important;
}
/*landing page css*/
.title1 {
  font-size: 35px;
  color: #000;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  line-height: 40px;
}
.title2 {
  color: rgba(0, 0, 0, 1);
  font-size: 50px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
.story-text {
  color: #291717;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 185%;
  margin-top: 20px;
}
.mt-52 {
  margin-top: 52px;
}
.mb-52 {
  margin-bottom: 52px;
}
.responsiveImage {
  max-width: 100%;
  height: auto;
  margin-top: -60px;
}
.ml-82 {
  margin-left: 82px;
}
.mr-82 {
  margin-left: 82px;
}

.p-80 {
  padding: 30px 0px 30px 0px;
}
.mt-35 {
  margin-top: 35px;
}
.center-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  height: 100vh; /* Optional: Set the height to 100% of the viewport height for vertical centering */
  /* Add any additional styles as needed */
}
.image-hover {
  padding: 8px;
}
.image-hover:hover {
  padding: 8px;
  border-radius: 8px;
  background: #eaecf0;
}
.basis-29 {
  flex-basis: 29% !important;
}
.basis-40 {
  flex-basis: 40% !important;
}
.basis-2 {
  flex-basis: 2% !important;
}
.min-ht-56 {
  min-height: 56px;
}
.min-ht-65 {
  min-height: 65px;
}
.min-ht-82 {
  min-height: 82px;
}
.min-ht-85 {
  min-height: 85px;
}
.min-ht-98 {
  min-height: 98px;
}

.w-96 {
  width: 96px;
}

.w-67 {
  width: 67px;
}

.space-evenly {
  justify-content: space-evenly;
}

.mr-10 {
  margin-right: 10px;
}

.w-150 {
  width: 154px;
}

.w-90 {
  width: 90px;
}

.wrap {
  flex-wrap: wrap;
}
.mt-60 {
  margin-top: 60px;
}
.navigation-menu {
  background-color: #fff !important;
  box-shadow: none !important;
}
.navigation-menu h3 {
  color: var(--black-100, #000);
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  margin-right: 20px;
  text-transform: none;
}
.navigation-menu .login-btn {
  display: flex;
  padding: 10px 25px;
  align-items: flex-start;
  gap: 10px;
  border: 1px solid #191a23;
  color: #000;
  border-radius: 14px;
  text-transform: none;

  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
}
.banner_title {
  font-size: 55px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.banner_title span {
  font-size: 55px !important;
}
.yellow_btn {
  border-radius: 8px;
  border: none;
  color: #000;
  background: var(--Primary, linear-gradient(83deg, #ff8a00 0%, #ffc000 100%));
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  padding: 10px 18px;
}
.white_btn {
  display: flex;
  width: 160px;
  height: 56px;
  padding: 10px 18px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  background-color: #fff;
  border-radius: 8px;
  border: none;
}
.mb-100 {
  margin-bottom: 100px !important;
}
.pb-100 {
  padding-bottom: 100px !important;
}
.pb-10 {
  padding-bottom: 10px !important;
}
.mb-120 {
  margin-bottom: 120px !important;
}
.pt-72 {
  padding-top: 72px;
}
.banner_title2 {
  font-size: 50px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 20px;
}
.subtitle {
  font-family:
    Poppins,
    Consolas,
    Courier New,
    monospace !important;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  line-height: 40px;
}
.box2 {
  padding: 20px !important;
  border-radius: 15px;
  border: 1.5px solid #fff;
  background: #f3f3f3;
  box-shadow: 0px 2px 5.5px 0px rgba(0, 0, 0, 0.02);
  backdrop-filter: blur(10.499999046325684px);
  height: 400px;
}

.grey-box .heading {
  color: #000;
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.mt-26 {
  margin-top: 26px;
}
.mt-8 {
  margin-top: 8px !important;
}
.tool-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 20px;
  max-width: initial !important;
}
.tools-main {
  border-radius: 15px;
  border: 1.5px solid #fff;
  background: linear-gradient(113deg, rgba(255, 255, 255, 0.82) 0%, rgba(255, 255, 255, 0.8) 110.84%);
  box-shadow: 0px 2px 5.5px 0px rgba(0, 0, 0, 0.02);
  backdrop-filter: blur(10.499999046325684px);
  padding: 20px;
}
.tools-main img {
  width: 48px;
  height: 48px;
  margin-right: 12px;
}
.tools-main h2 {
  color: #180909;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
.lists h1 {
  color: var(--black-100, #000);
  font-size: 50px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
.lists span {
  color: var(--black-100, #000);
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.lists p {
  color: rgba(28, 28, 28, 0.9);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}
.grid-card .grid-header {
  border-radius: 45px 45px 0px 0px;
  border: 1px solid rgba(255, 255, 255, 0.5);
  background: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(12px);
}
.grid-header h1 {
  color: #1c1c1c;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 33px;
}

.grid-card .grid-body {
  border-radius: 0px 0px 45px 45px;
  border: 1px solid rgba(255, 255, 255, 0.5);
  background: rgba(255, 255, 255, 0.3);
}

.grid-section {
  margin-top: 24px !important;
}
.center-align {
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}
.title3 {
  color: #000000cc;
  font-size: 50px !important;
  font-style: normal;
  font-weight: 300;
  line-height: 65px;
  margin-top: 163px;
  margin-bottom: 43px;
}
.pt-24 {
  padding-top: 24px !important;
}
.sub-grid {
  padding: 32px 44px;
}
.span-btn span {
  border-radius: 16px;
  background: #ffb74d7d;
  padding: 4px 8px;
}
.title4 {
  color: #180909;
  text-align: center;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-top: 90px;
  margin-bottom: 50px;
}
.title5 {
  text-align: center;
  font-size: 50px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.created-by {
  color: rgba(28, 28, 28, 0.4);

  font-family: Inter;
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  line-height: 24px;
}

.w-279 {
  width: 279px;
}
.h-34 {
  height: 34px;
}
.w-130 {
  width: 130px;
}
.h-36 {
  height: 36px;
}

.w-489 {
  width: 489px;
}

.w-381 {
  width: 381px;
}
.h-74 {
  height: 74px;
}
.tools-title {
  color: #180909;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-left: 12px;
}
.mb-60 {
  margin-bottom: 60px;
}
.grid_title {
  color: var(--black-80, rgba(28, 28, 28, 0.8));
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px; /* 122.222% */
}
.mt-200 {
  margin-top: 150px;
}

@media (max-width: 640px) {
  .provider-video-height {
    height: 150px;
    /* width: 100%; */
    /* height: 100%; */
  }
}

/*media queries on homepage*/
@media only screen and (min-width: 768px) {
  .video-grid {
    width: 996px;
    margin: 0 auto;
  }
}
@media only screen and (max-width: 600px) {
  .white-box {
    padding: 33px 6px 20px 20px;
    margin-top: 35px;
    margin-bottom: 35px;
  }
  .fs-30 {
    font-size: 17px;
    margin-top: 20px;
  }
  .navigation-menu .login-btn {
    padding: 4px 16px;
    position: absolute;
    right: 0;
    font-size: 16px;
  }
  .banner_title {
    font-size: 20px;
  }
  .banner_title span {
    font-size: 30px !important;
  }
  .title2 {
    font-size: 30px;
  }
  .banner_title2 {
    font-size: 30px;
  }
  .title1 {
    font-size: 25px;
  }
  .ml-82 {
    margin-left: 0px;
  }
  .title5 {
    font-size: 26px;
  }
  .title4 {
    font-size: 30px;
    line-height: 40px;
  }
  .title3 {
    font-size: 30px !important;
    line-height: 40px;
  }
  .responsiveImage {
    margin-top: 0px !important;
  }
  .grey-box .heading {
    font-size: 30px;
  }
  .box2 {
    height: auto;
  }
  .mr-82 {
    margin-left: 0px;
  }
  .center-container {
    height: auto;
  }
  .pt-72 {
    padding-top: 0px;
    padding-bottom: 72px;
  }
  .mb-100 {
    margin-bottom: 50px;
  }
  .p-25 {
    padding: 25px;
  }
  .pt-40 {
    padding-top: 0px !important;
  }
  .right-sec-wht-box {
    padding: 0px !important;
  }
  .fs-48 {
    font-size: 25px !important;
  }
}
.text-666 {
  color: #666666;
}
.text-545 {
  color: #545e6f;
}
.gap-5 {
  gap: 5px;
}
.gap-50 {
  gap: 50px;
}
.p-25 {
  padding: 25px;
}
.pb-2 {
  padding-bottom: 10px;
}
.rcb {
  color: rgba(0, 0, 0, 0.5);
}
.chatSend {
  border: 1px solid #1c1c1c4d !important;
  padding: 16px 10px !important;
  border-radius: 64px !important;
  margin-top: 30px !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 20px !important;
  letter-spacing: 0em;
  text-align: left;
  height: 52px !important;
}
.icon-attachment {
  display: flex !important;
  gap: 25px !important;
  padding-top: 5px;
}
.btn-gap label {
  margin-left: -15px !important;
}
/* sign in sign up page css */
.get_started_text {
  font-size: 36px;
  font-weight: 500;
}
.mb-40 {
  margin-bottom: 40px;
}
.auth-image {
  width: 180px;
  height: 45px;
  padding: 7px;
  margin-top: 25px;
}

.auth-texts {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  color: #000000;
}
.badge-logo {
  height: 20px;
  width: 20px;
}

.gradient-color {
  border: 2px solid #ffc000;
}

.divider {
  height: 15px !important;
  background-color: black !important;
  width: 1px !important;
}

.bgGrey {
  color: gray;
}
.LoginSocialGoogle {
  margin-top: 10px; /* Adjust margin */
  font-size: 12px; /* Adjust font size */
  height: 35px;
}
.list-class {
  list-style: none;
  display: flex;
  margin-bottom: 20px;
}
.input-br {
  border: 1px solid #0503001a;
  outline: none !important;
}
.basis-20 {
  flex-basis: 20%;
}
.w-30 {
  width: 30px !important;
}
.h-30 {
  height: 30px !important;
}
.container_chat {
  max-height: calc(100vh - 460px) !important;
  height: calc(100vh - 460px);
  overflow-y: auto !important;
  transition: all 1s ease-in-out;
  /* margin-bottom: 80px !important; */
}

.roadmap_container_chat {
  max-height: calc(220vh - 460px) !important;
  height: calc(220vh - 460px);
  overflow-y: auto !important;
  transition: all 1s ease-in-out;
  /* margin-bottom: 80px !important; */
}
.container_chat12 {
  height: 89vh !important;
  overflow-y: auto !important;
}
.mt-25 {
  margin-top: 25px;
}
.dateTimeHolder {
  text-align: center;
  width: 95%;
  margin-top: 15px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: #667085;
  margin-bottom: 15px;
}
.progress-holder {
  width: 100% !important;
  background: #fff;
  padding: 0 10px;
  height: 56px;
  border-radius: 12px !important;
  border: 1px solid #e5ecf6 !important;
}
.flex-basic-70 {
  flex-basis: 77% !important;
}
.flex-basic-30 {
  flex-basis: 23% !important;
}
.italic {
  font-style: italic !important;
}
.show {
  display: inline-block;
}

.hide {
  display: none;
}
/* sign in sign up page css */
.get_started_text {
  font-size: 36px;
  font-weight: 500;
}
.mb-40 {
  margin-bottom: 40px;
}
.auth-image {
  width: 180px;
  height: 45px;
  padding: 7px;
  margin-top: 25px;
}

.auth-texts {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  color: #000000;
}
.text-000 {
  color: #000000 !important;
}
.p-15 {
  padding: 15px !important;
}
.badge-logo {
  height: 20px;
  width: 20px;
}

.gradient-color {
  border: 2px solid #ffc000;
}

.divider {
  height: 15px !important;
  background-color: black !important;
  width: 1px !important;
}

.bgGrey {
  color: gray;
}
.LoginSocialGoogle {
  margin-top: 10px;
  font-size: 12px;
  height: 35px;
}
.input-br {
  border: 1px solid #0503001a;
  outline: none !important;
}

.show {
  display: inline-block;
}

.hide {
  display: none;
}
.pr-10 {
  padding-right: 10px !important;
}
.text-decoration-underline {
  text-decoration: underline;
}
.padding-24 {
  padding: 24px, 24px, 0px, 24px;
}

.input_fixed {
  background-color: rgb(255, 255, 255) !important;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0px;
  width: 96%;
}
.roadmap_input_fixed {
  /* background-color: rgb(255, 255, 255) !important; */
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 350px;
  width: 96%;
}
.mb-30 {
  margin-bottom: 30px !important;
}
.ht-96 {
  /* height: 72px !important; */
  background-color: #f7f9fb;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  padding: 24px !important;
  gap: 15px;
}
.mt-5 {
  margin-top: 5px !important;
}
.simple-spinner {
  width: 30px;
  height: 30px;
  transform: translate(-50%, -50%);
}

.simple-spinner span {
  display: block;
  width: 30px;
  height: 30px;
  border: 3px solid transparent;
  border-radius: 50%;
  border-right-color: rgba(5, 0, 0, 0.7);
  animation: spinner-anim 0.8s linear infinite;
}

@keyframes spinner-anim {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
.fs-8 {
  font-size: 8px;
}

.topRight {
  position: absolute;
  top: 5 !important;
  right: 15 !important;
}
.pc {
  color: #ff8a00;
}
.w-139 {
  width: 139px !important;
}

.overFlow-y {
  overflow: auto;
  height: 300px;
}
.notification-read {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  color: #00000080;
}
.notification-not-read {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  color: #00000080;
  font-weight: 700;
}

.bg-grey {
  background: #eef2f8;
  padding: 10px;
}

.maximized {
  height: 150vh !important;
  background-color: white;
  min-width: 100%;
  width: 100%;
  position: absolute;
  top: 6rem;
  left: 0;
  transition: top ease-in-out 0.3s;
  z-index: 100;
}

.bg-grey-notification {
  background: #fbfbfb !important;
}
.bg-white {
  background: #fff !important;
}

.activity-card-content_head {
  font-size: 14px !important;
  line-height: 22px !important;
  letter-spacing: 0.1px !important;
  color: #000000 !important;
}
.activity-card-content_desc {
  font-size: 14px !important;
  line-height: 22px !important;
  letter-spacing: 0.1px !important;
  color: #000000de !important;
  font-weight: 400 !important;
}
.w-80 {
  width: 80%;
}
.non-detail-pages-padding {
  margin-right: 17px !important;
}
.non-detail-pages-padding .css-1k455el {
  min-height: 40px !important;
}
@media (min-width: 900px) {
  .non-detail-pages-padding {
    margin-right: 5px !important;
  }
}
@media (min-width: 1026px) {
  .non-detail-pages-padding {
    margin-right: 22px !important;
  }
}
@media (min-width: 1140px) {
  .non-detail-pages-padding {
    margin-right: 20px !important;
  }
}
@media (min-width: 1205px) {
  .non-detail-pages-padding {
    margin-right: 10px !important;
  }
}
.input_fixed_tango {
  /* background-color: rgb(255, 255, 255); */
  /* display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0px; */
  /* left: 2%;
  right: 2%;
  height: 50px; */

  background-color: rgb(255, 255, 255) !important;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 10px;
  flex-direction: column;
  /* width: 96%; */
}

.input_fixed_agent {
  /* background-color: rgb(255, 255, 255); */
  /* display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0px; */
  /* left: 2%;
  right: 2%;
  height: 50px; */

  background-color: rgb(255, 255, 255) !important;
  display: flex;
  align-items: center;
  justify-content: center;
  /* position: absolute; */
  /* bottom: 10px; */
  flex-direction: column;
  /* width: 96%; */
}

.chatBoxParent_tango {
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Aligns children at the top and bottom */
  padding: 24px;
  height: 100%; /* Adjust based on your layout, ensure it's contained within its parent */
  box-sizing: border-box; /* Includes padding in the height calculation */
}

.chatContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%; /* Ensure the container takes full width */
}

.three-lines {
  display: -webkit-box;
  -webkit-line-clamp: 3; /* Limit to three lines */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  box-sizing: border-box;
  height: 6em;
}

.three-lines-inspiration {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3; /* Number of lines */
  overflow: hidden;
}

.three-lines-small {
  display: -webkit-box;
  -webkit-line-clamp: 4; /* Limit to three lines */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  box-sizing: border-box;
  height: 6em;
}

.inspiration-image-list {
  width: 200px; /* Fixed width */
  height: 150px; /* Fixed height */
  object-fit: cover;
  border-radius: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.h-40 {
  height: 40px;
}

.inverted-image {
  /* transform: scaleY(-1) !important; */
  /* background-color: red; */
  transform: scaleY(-1) !important;
  display: block !important;
}
.w-80 {
  width: 80%;
}
.px-100 {
  padding: 0 200px;
}
.ht_100vh {
  height: 100vh;
}
.p-5-8p {
  padding: 1px 10px;
}

.br-20 {
  border-radius: 20px;
}
.pb-1 {
  padding-bottom: 1px !important;
}
.inline-block {
  display: inline-block;
}
.pb-24 {
  padding-bottom: 24px !important;
}
.chatSend-tango {
  border: 1px solid #1c1c1c4d !important;
  /* padding: 16px 10px !important; */
  border-radius: 64px !important;
  /* margin-top: 30px !important; */
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 20px !important;
  letter-spacing: 0em;
  text-align: left;
  /* height: 52px !important; */
}

.chatBoxChat_tango {
  padding: 10px;
  /* background-color: #f6f7f8; */
  border-radius: 12px;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 20px;
  margin-right: 20px;
  padding-bottom: 5px;
  /* max-width: 100%; */
}

.chatBoxChat_agent {
  padding: 10px;
  /* background-color: #f6f7f8; */
  border-radius: 12px;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 10px;
  margin-right: 10px;
  /* padding-bottom: 5px; */
  /* max-width: 100%; */
}

.user-message {
  background-color: none;
}

.system-message-tango {
  display: block;
}

.tango_user_message_text_css {
  font-family: Inter;
  color: '#333333';
  font-weight: 400;
  font-size: 16px;
  background-color: #f6f7f8;
  border-radius: 8px;
}

.tango_system_message_option_back {
  padding: 10px;
  margin-top: 10px;
  border-radius: 10px;

  /* background-color: #fce9af;
  background-color: #fff5e5; */

  /* background: linear-gradient(83.26deg, rgba(255, 138, 0, 1) 0%, rgba(255, 192, 0, 1) 100%); */
  /* background: linear-gradient(0deg, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7)); */

  background-color: #fff5e5;
  /* background-color: #fff; */
  /* background-color: #fce9af; */

  /* background: linear-gradient(83.26deg, #ff8a00 0%, #ffc000 100%),
    linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9));

  background-image: linear-gradient(0deg, #ff8a00, #ffc000); */
}

.tango_user_message_text_back {
  background-color: #fff;
  padding: 10px;
  margin-top: 10px;
}

.fs-13 {
  font-size: 13px !important;
}

.fs-18 {
  font-size: 18px !important;
}

.reset-background {
  background: white;
  width: 100%;
  height: 100%;
}
@media (min-width: 763px) and (max-width: 1024px) {
  .reset-background {
    background: white;
    width: 100%;
    height: 100vh;
  }
}
.reset-input {
  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 20px;

  position: absolute;
  left: 0%;
  right: 0%;
  top: 45.64%;
  bottom: 36.93%;

  background: #ffffff;
  border: 1px solid #eeefef !important;
  border-radius: 15px;
}

.reset-image-size {
  width: 184px;
  height: 68px;
}
.text-align-right {
  text-align: right;
}
.mb-10 {
  margin-bottom: 10px;
}


.blue-teal {
  color: #175282 !important;
}
/* .animate {
  opacity: 0;
} */

@keyframes slideBottomRight {
  0% {
    transform: translate(15%, 90%);
    opacity: 0;
  }
  25% {
    transform: translate(0, 0);
    opacity: 1;
  }
  50% {
    transform: translate(0, 0);
    opacity: 1;
  }
  75% {
    transform: translate(0, 0);
    opacity: 1;
  }
  100% {
    transform: translate(0, 0);
    opacity: 1;
  }
}

@keyframes slideBottomLeft {
  0% {
    transform: translate(-15%, 90%);
    opacity: 0;
  }
  25% {
    transform: translate(0, 0);
    opacity: 1;
  }
  50% {
    transform: translate(0, 0);
    opacity: 1;
  }
  75% {
    transform: translate(0, 0);
    opacity: 1;
  }
  100% {
    transform: translate(0, 0);
    opacity: 1;
  }
}

/* .animate2 {
  opacity: 0;
} */

@keyframes slideBottomRight2 {
  0% {
    transform: translate(15%, 90%);
    opacity: 0;
  }
  25% {
    transform: translate(0, 0);
    opacity: 1;
  }
  50% {
    transform: translate(0, 0);
    opacity: 1;
  }
  75% {
    transform: translate(0, 0);
    opacity: 1;
  }
  100% {
    transform: translate(0, 0);
    opacity: 1;
  }
}

@keyframes slideBottomLeft2 {
  0% {
    transform: translate(-15%, 90%);
    opacity: 0;
  }
  25% {
    transform: translate(0, 0);
    opacity: 1;
  }
  50% {
    transform: translate(0, 0);
    opacity: 1;
  }
  75% {
    transform: translate(0, 0);
    opacity: 1;
  }
  100% {
    transform: translate(0, 0);
    opacity: 1;
  }
}

.p-0-10 {
  padding: 0px 10px !important;
}
.pt-0 {
  padding-top: 0px !important;
}
.px-15 {
  padding-left: 15px !important;
  padding-right: 15px !important;
}
.minW-150 {
  min-width: 150px !important;
}
.minH-40 {
  min-height: 40px !important;
}
.minW-250 {
  min-width: 250px !important;
}
.minH-50 {
  min-height: 50px !important;
}
.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 4px 15px !important;
  height: 35px !important;
}
.mt-3 {
  margin-top: 3px !important;
}
.text-transform-capitalize {
  text-transform: capitalize;
}
/* .date-picker-height {
  padding: 16.5px 14px;
  height: 35px;
} */
.closeAccordion {
  position: absolute;
  left: 14%;
  top: 14px;
}
.openAccordion {
  margin-left: 0%;
  position: 'absolute';
  left: '10px';
}
.single-select-box {
  border-radius: 8px;
  padding: 0px 15 px;
  border: 1px solid #0503001a;
  height: 43px;
  margin-top: 10px;
}
.single-select-box input {
  font-size: 14.5px !important;
}
.single-select-box .css-xkdlhh-MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-endAdornment {
  right: -9px;
}

.email_holder_15 div div {
  padding: 0 9px !important;
}
.slide {
  transition: transform 0.5s ease; /* Add any CSS transition properties you want */
}

.py-20 {
  padding: 20px 0px;
}

.minH-55 {
  min-height: 55px !important;
}

.align-items-center {
  align-items: center !important;
}
.createProject-SubmitBtn {
  padding: 0px 16px !important;
  color: #3f51b5 !important;
  background-color: transparent !important;
  letter-spacing: 1.25px !important;
  font-size: 14px !important;
  line-height: 36px !important;
  font-weight: 500 !important;
  border: none !important;
  text-align: end !important;
  box-shadow: none !important;
}

.w-FitContent {
  width: fit-content !important;
}
.h-FitContent {
  height: fit-content !important;
}
.pl-20 {
  padding-left: 20px !important;
}

input:disabled::-webkit-input-placeholder {
  color: #000000 !important;
  opacity: 1 !important;
}
.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 4px 15px !important;
  height: 38px !important;
}

.pos-relative {
  position: relative !important;
}

.px-35 {
  padding: 0px 35px !important;
}
.op-5 {
  opacity: 0.5 !important;
}


.line-ht-28 {
  line-height: 28px !important;
}

.mb-32 {
  margin-bottom: 32px !important;
}
.form_scroll::-webkit-scrollbar {
  display: none !important;
}




@media (max-width: 600px) {
  .hide-on-small {
    display: none;
  }
}

.no-wrap {
  white-space: nowrap;
}
.wrap {
  white-space: wrap;
}

.break-word {
  word-break: break-word;
}

.bottom-0 {
  position: absolute;
  bottom: 0 !important;
}

.pl-80 {
  padding-left: 80px;
}

.ht-45px {
  height: 45px;
}
.avtar_hts {
  height: 30px !important;
  width: 30px !important;
  padding: 10px !important;
}
.d-none {
  display: none;
}

.loading-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-content div {
  width: 8px;
  height: 8px;
  margin: 3px;
  background-color: #333;
  border-radius: 100%;
  display: inline-block;
  animation: bouncing-loader 0.6s infinite alternate;
}

@keyframes bouncing-loader {
  from {
    opacity: 1;
    transform: translateY(8px);
  }
  to {
    opacity: 0.1;
    transform: translateY(-8px);
  }
}

.loading-content div:nth-of-type(2) {
  animation-delay: 0.2s;
}

.loading-content div:nth-of-type(3) {
  animation-delay: 0.4s;
}

.top-bd1-tango {
  height: 10px;
  background-image: linear-gradient(to right, #ff8a00, #ffc000);
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  margin-top: -1px;
}


.left-bd-tango {
  /* height: 7px; */
  width: 8px;
  background-image: linear-gradient(to right, #ff8a00, #ffc000);
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.left-bd-tango2 {
  width: 6px;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 100;
  background-image: linear-gradient(to right, #ff8a00, #ffc000);
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.left-bd-tango-only-space {
  width: 20px;
  background-image: white;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.mc-cards-pin {
  display: flex;
  background-color: #fff;
  height: 100%;
  justify-content: space-evenly;
  gap: 12px;
  align-items: center;
  border-radius: 0px 0px 10px 10px;
  overflow-x: auto;
  margin: 12px;
}

.mc-card-pin-1 {
  position: relative;
  height: 97%;
  width: 30%;
  background-color: chocolate;
  border: 1px solid #d2d7e1;
  border-radius: 10px;
  overflow: hidden;
}

.mc-cards-pin::-webkit-scrollbar {
  height: 5px;
}

.avtar_ht-tango {
  height: 32px !important;
  width: 32px !important;
  /* padding: 10px !important; */
}

.importantFlexDirection {
  display: flex !important;
  flex-direction: row !important;
  align-items: center;
}

.container_chat13 {
  max-height: calc(100vh - 510px) !important;
  height: calc(100vh - 510px);
  overflow-y: auto !important;
  transition: all 1s ease-in-out;
  /* margin-bottom: 80px !important; */
}

.container_business_case {
  /* max-height: calc(100vh - 510px) !important;
  height: calc(100vh - 510px); */
  overflow-y: auto !important;
  transition: all 1s ease-in-out;
  /* margin-bottom: 80px !important; */
}

.apexcharts-tooltip {
  z-index: 9999 !important;
  overflow: visible !important;
}

.profileDrawer .MuiPaper-elevation::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.tango-button {
  background-color: #fff5e6 !important;
  border-radius: 24px !important;
  padding: 10px;
  color: #141932b2 !important;
  font-style: italic;
}

.btn-date-update-opp {
  padding: 6px 8px 6px 10px;
  gap: 0px;
  opacity: 0px;
  font-size: 14px;
  font-weight: 400;
  line-height: 25px;
  margin-top: 10px;
  text-align: left;
  background: #f0f1f3;
  border-radius: 8px;
  overflow-y: scroll;
  height: auto;
}

.providerpagesTest {
  font-family:
    Poppins,
    Consolas,
    Courier New,
    monospace;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  color: #545e6f;
}

.profileCards {
  width: Fill (146.5px) px;
  height: Fixed (169px) px;
  padding: 20px 16px 16px 16px;
  gap: 8px;
  border-radius: 12px 0px 0px 0px;
  border: 1px 0px 0px 0px;
  opacity: 0px;
}

.cr-selected-card {
  background-color: #e0f7fa;
  border: 2px solid #00796b;
  color: #004d40;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transition:
    background-color 0.3s,
    border 0.3s;
}

.pr-scope-grey-card {
  background-color: #f5f5f5;
  border: 1px solid #d3d3d3;
  color: #616161;
  box-shadow: none;
  transition:
    background-color 0.3s,
    border 0.3s;
}
.d-flex-column-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ql-editor ul,
.ql-editor ol {
  padding-left: 20px;
  list-style-position: inside;
}

.ql-editor li {
  margin-bottom: 5px;
}

.tourButton {
  font-family:
    Poppins,
    Consolas,
    Courier New,
    monospace;
  font-size: 14;
  gap: 10px;
  margin: 10px 10px 0px 0px;
  width: 71px;
  height: 30px;
  color: #000000;
  background: #ffd380;
  border: 0.25px solid #fac036;
  border-radius: 5px;
  align-items: center;
  display: flex;
  justify-content: center;
}

.text-212121 {
  color: #212121;
}

.flex-container-v2-row {
  display: flex;
  flex-direction: row;
}

.flex-container-v2-col {
  display: flex;
  flex-direction: column;
}

.linkStyle {
  color: black !important;
  text-decoration: none;
}
.glow {
  transition: box-shadow 0.3s ease;
}

.glow:hover {
  box-shadow: 0 0 10px rgba(254, 199, 1, 0.989);
}

.activeglow {
  box-shadow: 0 0 10px rgba(254, 199, 1, 0.989);
}

.white_btn_tour {
  display: flex;
  width: 180px;
  height: 40px;
  padding: 10px 18px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border: 2px solid #ebebeb !important;
  flex-shrink: 0;
  background-color: #fff;
  border-radius: 8px;
  border: none;
}

.p-100 {
  padding: 100px;
}

.d-flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.hover-container {
  position: relative;
}

.tango-assist-button {
  opacity: 0;
  transition: opacity 0.3s ease;
}

.hover-container:hover .tango-assist-button {
  opacity: 1;
}
.buttonTango {
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
  min-width: none;

  transition: width 0.5s cubic-bezier(0.42, 0, 0.58, 1);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  &:hover {
    width: 200px;
    transition: width 0.5s cubic-bezier(0.42, 0, 0.58, 1);
  }
}

.button-content {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  transition: transform 0.5s cubic-bezier(0.42, 0, 0.58, 1);
  &:hover {
    justify-content: flex-start;
    padding-left: 5px;
  }
}

.button-img {
  display: block;
  transition: transform 0.5s cubic-bezier(0.42, 0, 0.58, 1);
}

.button-text {
  position: absolute;
  left: 25%;
  white-space: nowrap;
  opacity: 0;
  transform: translateX(10px);
  transition:
    opacity 0.5s cubic-bezier(0.42, 0, 0.58, 1),
    transform 0.5s cubic-bezier(0.42, 0, 0.58, 1);
}

.buttonTango:hover .button-img {
  transform: translateX(-5px);
}

.buttonTango:hover .button-text {
  opacity: 1;
  transform: translateX(0);
}

.text-red {
  color: 'red';
}
.plrb-16 {
  padding: 0 16px 16px 16px;
}

.MuiToggleButton-root.Mui-disabled {
  border: none !important;
}

.floating-arrows {
  transform: translateX(-50%);
  background-color: #ffffff;
  border: 3px solid #fab64c;
  cursor: pointer;
  z-index: 10;
  transition: opacity 0.3s;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.floating-arrows:hover {
  opacity: 0.8;
}

.MuiDataGrid-scrollbar.MuiDataGrid-scrollbar--horizontal.css-1rtad1 {
  --scrollbar-size: 8px;
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
}

.MuiDataGrid-scrollbar.MuiDataGrid-scrollbar--horizontal.css-1rtad1::-webkit-scrollbar {
  height: var(--scrollbar-size);
}

.MuiDataGrid-scrollbar.MuiDataGrid-scrollbar--horizontal.css-1rtad1::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  border-radius: 10px;
}

.MuiDataGrid-scrollbar.MuiDataGrid-scrollbar--horizontal.css-1rtad1::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
  border: 2px solid #f1f1f1;
}

.MuiDataGrid-scrollbar.MuiDataGrid-scrollbar--horizontal.css-1rtad1::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

.acc_slider_blue span:first-child {
  background: #eaecf0 !important;
  height: 8px !important;
}

.acc_slider_blue span:nth-child(2) {
  background: linear-gradient(83deg, #114b7a 0%, #114b7a 100%) !important;
  height: 8px;
}
.pr-scope-status {
  height: auto;
  background: white;
  /* margin: 0px 24px 24px 24px; */
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  /* padding: 0px 24px 24px 24px; */
  flex-direction: column;
}

.mt-120 {
  margin-top: 120px !important;
}

.p-0 {
  padding: 0px !important;
}

.fs-8 {
  font-size: 8px;
}

.custom-scrollbar {
  --scrollbar-size: 6px;
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
}

.custom-scrollbar::-webkit-scrollbar {
  height: var(--scrollbar-size);
  width: var(--scrollbar-size);
}

.custom-scrollbar::-webkit-scrollbar-button {
  display: none;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  border-radius: 10px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
  border: 2px solid #f1f1f1;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}
.arrow_box {
  padding: 6px 8px;
  font-size: 12px;
  font-family:
    Poppins,
    Consolas,
    Courier New,
    monospace;
}

.cropContainer {
  position: relative;
  width: 100%;
  height: 400px;
  background-color: #000;
  margin-bottom: 16px;
}

.controls {
  display: flex;
  flex-direction: column; /* Stack the slider and button vertically */
  align-items: center; /* Center-align slider and button horizontally */
  gap: 16px; /* Add spacing between slider and button */
}

.sliderContainer {
  width: 80%; /* Adjust width as needed */
}

.acc_slider21 {
  width: 100%; /* Ensures slider takes the full width of the sliderContainer */
}

.cropButton {
  margin-top: 8px; /* Optional, additional spacing from slider */
}
